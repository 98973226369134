#me {
	border-radius: 25px;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px;
	width: 150px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;
}