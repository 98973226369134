@font-face {
	font-family: "Quicksand";
	src: url("static/media/Quicksand-VariableFont_wght.ttf");
	}

@font-face {
	font-family: "Montserrat";
	src: url("static/media/Montserrat-Regular.ttf");
}

@font-face {
	font-family: "Montserrat-Light";
	src: url("static/media/Montserrat-Light.ttf");
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

h1 {
	color: #f6f4e6;
	font-size: 3rem;
	text-align: center;
	font-family: 'Quicksand', sans-serif;
	letter-spacing: .2em;
	text-shadow: 0 0 5px #fff, 0 0 20px #fff;
	margin-bottom: 7px;
}

h2 {
	color: #f6f4e6;
	text-align: center;
	letter-spacing: .01em;
	margin-left: 30%;
	margin-right: 30%;
	font-size: .9rem;
	font-family: 'Montserrat-Light', sans-serif;
	letter-spacing: .07em;
}


.helloworld {
	color: rgb(146, 107, 197);
	margin-top: 2%;
	font-family: 'Courier New', Courier, monospace;
	text-shadow: 0.1rem 0.1rem #091d2b
}

.container {
	text-align: center;
}

body {
  background: #272727;
  font-family: "Montserrat", sans-serif;
}

.navMenu {
	text-align: center;
	margin-bottom: 15px;
	position: relative;
}

.navMenu a {
  color: #f6f4e6;
  text-decoration: none;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  width: 80px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navMenu a:hover {
  color: #fddb3a;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes animate{
	100%{
	  transform: translate(-100%, 0);
	}
	0%{
		transform: translate(100%, 0);
	}
  }

.marquee {
	width: 50%;
	overflow: hidden;
	text-align: center;
}

.marquee-text {
	white-space: nowrap;
	animation: animate 30s linear infinite; 
	font-size: .8rem;
	text-align: center;
}