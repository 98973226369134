.jobspacing {
	margin-bottom: 10px;
}

.jobtitlesize {
	font-size: 1.3rem;
}

p {
	text-align: center;
	font-family: 'Montserrat-Light', sans-serif;
	font-size:1.2rem;
}

ul {
	font-size: .8rem;
	text-align: center;
	font-family: 'Montserrat-Light', sans-serif;
    padding-left: 0;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

ul li {
  margin-left: 80px;
  margin-bottom: 3px;
  word-wrap: break-word;
  text-align: left;
}

.techused {
	font-size: .8rem;
}

