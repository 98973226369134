.navbar-container {
	text-align: center;
	margin-bottom: 15px;
	position: relative;
}

.navbar-container a {
  color: #f6f4e6;
  text-decoration: none;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  width: 80px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navbar-container a:hover {
  color: #f9e891;
}